import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueNumerals from 'vue-numerals';
import number from '@coders-tm/vue-number-format';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueCookies, { expires: '1d' });
Vue.use(VueNumerals);
Vue.use(number, { precision: 4 });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
