<template>
  <div id="app">
    <app-styles></app-styles>
    <router-view/>
  </div>
</template>

<script>
import Styles from '@/components/Ztyles/Styles.vue';

export default {
  name: 'App',
  components: {
    'app-styles': Styles,
  },
  methods: {
    checkCookiesForHash() {
      const hash = this.$cookies.get('hash');
      if (hash) {
        this.$store.commit('setHash', hash);
      }
    },
  },
  mounted() {
    this.checkCookiesForHash();
  },
};
</script>
